<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/invoice.png" alt />
        <span class="center">发放明细</span>
        <img src="../../assets/right.png" alt />
        <span class="center">发放列表</span>
      </div>
    </div>
    <div style="display: flex; align-items: center;justify-content: space-between;padding:10px 0 ">
      <div style="display: flex; align-items: center">
        <span class="seach_text">关键字:</span>
        <el-input
          placeholder="请输入发放企业"
          v-model="keywords"
          size="mini"
          style="margin-right: 10px; width: 250px"
          prefix-icon="el-icon-search"
          clearable
        ></el-input>

        <span class="seach_text">支付状态：</span>

        <el-select v-model="payState" style="width: 230px;margin-right:10px" size="mini" clearable>
          <el-option label="全部" value></el-option>
          <el-option label="已完成" value="1"></el-option>
          <el-option label="部分完成" value="3"></el-option>
          <el-option label="失败" value="2"></el-option>
          <el-option label="支付中" value="0"></el-option>
        </el-select>

        <el-button size="mini" type="primary" @click="loadData('seath')">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="发放企业">
        <template slot-scope="scope">{{ scope.row.enterpriseName }}</template>
      </el-table-column>

      <el-table-column label="发放人数">
        <template slot-scope="scope">
          <span>{{ scope.row.makerNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column label="结算金额(￥)">
        <template slot-scope="scope">
          <span>{{ scope.row.total }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="支付银行">
        <template slot-scope="scope">
          <span v-if="scope.row.payInfo">{{ scope.row.payInfo.bank_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="支付账号">
        <template slot-scope="scope">
          <span v-if="scope.row.payInfo">{{ scope.row.payInfo.bank_code }}</span>
        </template>
      </el-table-column>-->
      <el-table-column label="支付状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.payState==='0'" type="success">支付中</el-tag>
          <el-tag v-if="scope.row.payState==='1'" type="success">已完成</el-tag>
          <el-tag v-if="scope.row.payState==='3'" type="warning">部分完成</el-tag>
          <el-tag v-if="scope.row.payState==='2'" type="danger">失败</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="支付时间">
        <template slot-scope="scope">
          <span>{{ scope.row.payTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="goedit(scope.row.code)" type="text">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      class="showImgDialog"
      width="680px"
      :center="true"
      :title="dialogTitle"
      :visible.sync="showImgVisible"
      :append-to-body="true"
    >
      <div class="imgBox">
        <img :src="dialogImgUrl" class="img-resource" alt />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const upperEnterpriseDispatchList = api()('upper_enterprise.dispatch.list.json');

export default {
  data () {
    return {
      tableData: [

      ],
      dialogTitle: '',
      keywords: '',
      showImgVisible: false,
      dialogImgUrl: '',
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      payState: ''
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    showImg (img, title) {
      this.dialogImgUrl = img;
      this.dialogTitle = title;
      console.log(this.dialogImgUrl);
      this.showImgVisible = true;
    },
    addProject () {
      this.$router.push({
        path: '/system/invoice/add',
      });
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData (seath) {
      if (seath) {
        this.pageNumber = 1;
      }
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        keywords: this.keywords,
        payState: this.payState
      };
      upperEnterpriseDispatchList(params).then((res) => {
        this.tableData = res.list
        console.log(res.list)
        this.total = res.total;
      });
    },
    goShow (code) {

      this.tableData.forEach(it => {
        if (it.code === code) {
          console.log(it)
          it.passShow = !it.passShow
        }
      })

    },
    goedit (code) {
      this.$router.push({
        path: '/system/grant/detail',
        query: { code },
      });
    },

  },
}
</script>
<style lang="scss" scope>
.showImgDialog .imgBox {
  display: flex;
}
.img-resource {
  width: 300px;
  height: 250px;
  margin: 0 auto;
}
.font-img {
  font-size: 26px;
  cursor: pointer;
  color: cadetblue;
}
</style>